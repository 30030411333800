.subscription-plans {
  padding: 50px 15px;
  background-color: #f2f2f2;
  text-align: center;

  &__title {
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
}

.plan-card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: 300px;
  padding: 25px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s;

  &__badge {
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #ff8c00;
    color: #fff;
    padding: 7px 12px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: bold;
  }

  &__name {
    font-size: 26px;
    margin-bottom: 15px;
    color: #333;
  }

  &__price {
    font-size: 28px;
    margin-bottom: 25px;
    color: #333;

    &-value {
      font-weight: bold;
    }

    &-period {
      font-size: 16px;
      color: #777;
    }
  }

  &__features {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 25px;
    text-align: left;

    & .plan-card__feature {
      margin-bottom: 12px;
      font-size: 15px;
      color: #555;
      padding-left: 20px;
      position: relative;

      &::before {
        content: '✔';
        position: absolute;
        left: 0;
        color: #4caf50;
      }
    }
  }

  &__button {
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 12px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ffffff;
      color: black;
      border: 1px solid black;
    }

    &:disabled {
      background-color: #ffffff;
      color: #000000;
      cursor: not-allowed;
      border: 1px solid black;
    }
  }

  &--popular {
    border: 2px solid #ff8c00;
    transform: scale(1.08);
    margin: 20px 0px;
  }

  &--current {
    // border: 2px solid #4caf50;
    .plan-card__badge {
      background-color: #4caf50;
    }
  }
}

// Media Queries for Responsiveness

@media (max-width: 992px) {
  .plan-card {
    width: 48%;
  }
}

@media (max-width: 768px) {
  .plan-card {
    width: 90%;
  }
}
