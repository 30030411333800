.store-details-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #f2f2f2;
    min-height: 100vh;

    &__title {
        font-size: 25px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    &__forms {
        display: flex;
        flex-direction: row;
    }
}

// Store Settings Form styles
.store-settings-form {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    // margin-right: 10px;

    &__group {
        margin-bottom: 15px;
    }

    &__label {
        font-size: 14px;
        margin-bottom: 5px;
    }

    &__input {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
    }

    &__update-button {
        padding: 10px;
        background-color: #000000;
        border: 1px solid black;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
            color: #000000;
            border: 1px solid black;
            background-color: #fafcff;
        }
    }
}

.store-settings-form__group.logo-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .logo-preview {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0;
    margin-bottom: 1rem;
  }
  
  .logo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .logo-placeholder {
    color: #999;
    font-size: 0.9rem;
    text-align: center;
  }
  
  .logo-upload-button {
    background-color: #000000;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid #000000;
  }
  
  .logo-upload-button:hover {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
  }
// Package Dimensions Form styles


@media (max-width: 600px) {

  .store-details-container__forms{
    display: flex;
    flex-direction: column!important;
    gap: 10px;
  }
}