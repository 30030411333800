.order-success-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 10px;
  }
  
  .order-success-content {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .order-success-icon {
    color: #4caf50;
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .order-id-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    font-size: 16px;
    justify-content: center;
  }
  
  .order-id-label {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .order-id-value {
    margin-right: 10px;
  }
  
  .copy-icon {
    cursor: pointer;
    color: #4caf50;
  }
  
  .order-success-button {
    background: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
  }
  
  .order-success-button:hover {
    background: #45a049;
  }
  .track-order-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.track-order-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.track-order-label {
  font-size: 14px;
  color: #007BFF; // A modern blue color for links
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
  text-decoration: none;
  &:hover {
      color: #0056b3; // Darker blue on hover
  }

  &:focus {
      outline: none;
      text-decoration: none;
      border-bottom: 1px solid #0056b3; // Subtle underline on focus
  }
}

.credit {
  text-align: center; // Center the text
  margin-top: 20px; // Add some space above the credit text
  font-size: 10px; // Set a smaller font size
  color: #666; // Use a subtle color for the text

  a {
    color: #007bff; // Use a blue color for the link
    text-decoration: none; // Remove underline
    transition: color 0.3s ease; // Smooth transition for color change

    &:hover {
      color: #0056b3; // Darker blue on hover
      text-decoration: underline; // Underline on hover
    }
  }
}