.navbar-seller {
    height: 50px;
    // width: 90%;
    // border-bottom: 0.5px solid #d3d3d3d1;
    // background-color: black;
    padding: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgb(255, 255, 255);
    background: linear-gradient(90deg, rgb(0 0 0) 0%, rgb(66 57 51) 44%, rgb(52, 51, 51) 100%);
    // background: linear-gradient(90deg, rgba(255, 93, 93, 1) 0%, rgba(241, 134, 134, 1) 44%, rgba(255, 255, 255, 1) 90%);
    // max-width: 100%;
    overflow: hidden;
    position: fixed;
    width: 100%;
    z-index: 10;

    h3{
        color: #ccc9c9;
    }

    // width: calc(100vw - var(30));
    .wrapper {
        width: 100%;
        display: flex;
        padding: 20px;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width:900px) {

    .navbar-seller {
        // width: 90vw;
    }

}

@media (max-width:525px) {

    .navbar-seller {
        width: 100vw;
    }

    .navbar-seller__user {
        margin-left: 50px;
    }
}