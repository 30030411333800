.change-password {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;

    &__container {
        background-color: #ffffff;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    &__content {
        max-width: 400px;
        margin: 0 auto;
    }

    &__title {
        font-size: 24px;
        margin-bottom: 1.5rem;
        text-align: center;
    }

    &__form {
        display: flex;
        flex-direction: column;

        &-group {
            margin-bottom: 1rem;

            & .change-password__label {
                font-size: 14px;
                margin-bottom: 0.5rem;
                display: block;
            }

            & .change-password__input {
                width: 100%;
                padding: 0.75rem;
                border: 1px solid #ccc;
                border-radius: 4px;
                font-size: 14px;
                box-sizing: border-box;
            }
        }

    }
    &__button {
        padding: 0.75rem;
        background-color: #000000;
        color: #ffffff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;

        &:hover {
            background-color: #ffffff;
            border: 1px solid black;
            color: black;
        }
    }

    &__loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }


    &__error-message {
        color: #ff0000;
        text-align: center;
        margin-top: 1rem;
    }
}